import React from 'react';
import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const StatBox = styled(Grid)({
  marginTop: '35px',
});

const StatInside = styled('div')((props) => ({
  maxWidth: '100%',
  height: 'auto',
  textAlign: 'center',
  backgroundColor: props.bgcolor,
  color: props.bgcolor === '#FF00F9' ? 'white' : '#230069',
  borderRadius: '9999px',
  padding: '5px !important',
}));

const SalesStats = ({ data }) => {
  const res = Math.max.apply(
    Math,
    data.map((o) => o.price)
  );

  const salesSum = data
    .map((item) => item.price)
    .reduce((prev, curr) => prev + curr, 0);

  const avgSalePrice = salesSum / data.length;

  return (
    <Grid container justify='space-between' spacing={3}>
      <StatBox item xs={6} lg={3}>
        <StatInside bgcolor='#FF00F9'>
          <Typography variant='subtitle1'>Top Sale</Typography>
          <Typography variant='subtitle1'>
            {Number(Math.round(res + 'e2') + 'e-2').toFixed(2)} Flow
          </Typography>
        </StatInside>
      </StatBox>
      <StatBox item xs={6} lg={3}>
        <StatInside bgcolor='#FFEE50'>
          <Typography variant='subtitle1'>Total Volume</Typography>
          <Typography variant='subtitle1'>
            {Number(Math.round(salesSum + 'e2') + 'e-2').toFixed(2)} Flow
          </Typography>
        </StatInside>
      </StatBox>
      <StatBox item xs={6} lg={3}>
        <StatInside bgcolor='#FF00F9'>
          <Typography variant='subtitle1'>Avg. Sale</Typography>
          <Typography variant='subtitle1'>
            {Number(Math.round(avgSalePrice + 'e2') + 'e-2').toFixed(2)} Flow
          </Typography>
        </StatInside>
      </StatBox>
      <StatBox item xs={6} lg={3}>
        <StatInside bgcolor='#FFEE50'>
          <Typography variant='subtitle1'>Total Sales</Typography>
          <Typography variant='subtitle1'>{data.length}</Typography>
        </StatInside>
      </StatBox>
    </Grid>
  );
};

export default SalesStats;
