import React, { useEffect, useState, useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';

const columns = [
  { field: 'id', headerName: 'Id', width: 150 },
  { field: 'price', headerName: 'Price', type: 'number', width: 150 },
  { field: 'seller', headerName: 'Seller', width: 150 },
  { field: 'buyer', headerName: 'Buyer', width: 150 },
  { field: 'saleType', headerName: 'Type', width: 150 },
  {
    field: 'Link',
    width: '450',
    renderCell: (params) => (
      <a
        href={
          params.row.saleType === 'Avatar'
            ? `https://flovatar.com/flovatars/${params.row.id}/${params.row.buyer}`
            : `https://flovatar.com/components/${params.row.id}/${params.row.buyer}`
        }
        target='_blank'
        rel='noreferrer'
      >
        Link
      </a>
    ),
  },
];

const SalesHistory = ({ data }) => {
  return (
    <div
      style={{
        height: '800px',
        width: '100%',
        background: 'white',
        borderRadius: '30px',
        marginTop: '35px',
      }}
    >
      <DataGrid
        rows={data}
        columns={columns}
        style={{ borderRadius: '30px', border: 'none !important' }}
      />
    </div>
  );
};

export default SalesHistory;
