const signalR = require('@microsoft/signalr');
const axios = require('axios');

export default function GraffleSDK() {
  let negotiateResult;
  const projectID = process.env.REACT_APP_GRAFFLE_PROJECT_ID;
  const negotiate = async () => {
    const authHeader = {
      'graffle-api-key': process.env.REACT_APP_GRAFFLE_API_KEY,
    };
    const url =
      'https://prod-main-net-af.azurewebsites.net/api/negotiateLiveStream';

    negotiateResult = await axios.post(url, {}, { headers: authHeader });
  };

  this.stream = async (streamCallback) => {
    await negotiate();
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(negotiateResult.data.url, {
        accessTokenFactory: () => negotiateResult.data.accessToken,
      })
      .withAutomaticReconnect()
      .build();

    if (connection) {
      connection.start().then((result) => {
        connection.on(projectID, (message) => {
          var parsedMessage = JSON.parse(message);
          streamCallback(parsedMessage);
        });
      });
    }
    return connection;
  };
}
