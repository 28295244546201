import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import HomePage from './Views';
const mdTheme = createTheme({
  typography: {
    subtitle1: {
      fontWeight: 700,
      fontSize: '16pt',
      textTransform: 'uppercase',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <HomePage />
    </ThemeProvider>
  );
}

export default App;
