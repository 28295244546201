import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { styled } from '@mui/system';
import Container from '@mui/material/Container';
import SalesStats from '../Components/SalesStats';
import Banner from '../Components/Banner';
import SalesHistory from '../Components/SalesHistory';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import GraffleSDK from '../Utils/StreamSDK';

const MainContainer = styled(Container)({
  backgroundColor: '#431C8F',
  height: '100%',
});

const HomePage = () => {
  const [apiData, setApiData] = useState(null);
  const conn = useRef();

  const streamSDK = new GraffleSDK('becc9320-aeb8-4680-9926-b58e9b4d9266');

  const foo = (message) => {
    if (
      message.flowEventId.includes('FlovatarComponentPurchased') ||
      message.flowEventId.includes('FlovatarPurchased')
    ) {
      console.log(message);
      setApiData((prevState) => [
        {
          id: message.blockEventData.id,
          price: message.blockEventData.price,
          seller: message.blockEventData.from,
          buyer: message.blockEventData.to,
          saleType: message.flowEventId.includes('FlovatarComponentPurchased')
            ? 'Component'
            : 'Avatar',
        },
        ...prevState,
      ]);
    }
  };

  useEffect(() => {
    let eventsArray = [];
    const fetchData = async () => {
      const result = await axios.get(
        'https://prod-main-net-dashboard-api.azurewebsites.net/api/company/eff32067-3730-45aa-80b8-491112dddf27/search?eventType=A.921ea449dffec68a.FlovatarMarketplace.FlovatarComponentPurchased,A.921ea449dffec68a.FlovatarMarketplace.FlovatarPurchased'
      );
      result.data.map((event) => {
        eventsArray.push({
          id: event.blockEventData.id,
          price: event.blockEventData.price,
          seller: event.blockEventData.from,
          buyer: event.blockEventData.to,
          saleType: event.flowEventId.includes('FlovatarComponentPurchased')
            ? 'Component'
            : 'Avatar',
        });
      });

      setApiData(eventsArray);
    };
    fetchData();
  }, []);

  useEffect(() => {
    conn.current = streamSDK.stream(foo);
    return () => {
      conn.current.stop();
    };
  }, []);

  return (
    <MainContainer maxWidth='xl'>
      <Banner />
      {apiData === null ? (
        <Grid container>
          <Grid item xs={12}>
            <CircularProgress color='secondary' size='10rem' />
          </Grid>
        </Grid>
      ) : (
        <>
          <SalesStats data={apiData} />
          <SalesHistory data={apiData} />
        </>
      )}
      <Grid container>
        <Grid item xs={12} style={{ color: 'white', margin: '35px auto' }}>
          Powered by{' '}
          <a
            href='https://graffle.io/'
            target='_blank'
            rel='noreferrer'
            style={{ color: '#FF6622', textDecoration: 'none' }}
          >
            Graffle.io
          </a>
        </Grid>
      </Grid>
    </MainContainer>
  );
};

export default HomePage;
