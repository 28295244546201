import React from 'react';
import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const BannerText = styled(Typography)((props) => ({
  color: '#FF00F9',
  fontSize: '3rem',
  textAlign: 'center',
  backgroundColor: 'white',
  borderRadius: 9999,
  padding: '30px',
  margin: 'auto',
  fontWeight: 600,

  [props.theme.breakpoints.up('sm')]: {
    maxWidth: '100%',
  },

  [props.theme.breakpoints.up('md')]: {
    maxWidth: '50%',
  },
}));

const Banner = () => {
  return (
    <Grid container style={{ paddingTop: '50px' }}>
      <Grid item xs={12}>
        <BannerText variant='h1' style={{}}>
          Flovatar Sales Tracker
        </BannerText>
      </Grid>
    </Grid>
  );
};

export default Banner;
